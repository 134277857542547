import dayjs from "dayjs";
import {DEFAULT_LANG, DEMO_DATE_FORMAT, LANGUAGE_CONSTANT, PLACEHOLDER} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const setLocalMonthName = (date, formattedDate) => {
    const month = dayjs(date).format(PLACEHOLDER.MonthFormat);

    return formattedDate.replace(month, LANGUAGE_CONSTANT[SITE_LANG].month[month]);
};

export const getDateText = (date) => {
    let dateFormatted = dayjs(date).format(LANG_SETTING[SITE_LANG].DateFormat);

    if (SITE_LANG === 'ru') {
        dateFormatted = setLocalMonthName(date, dateFormatted);
    }

    return LANG_SETTING[SITE_LANG].DateText.replace(PLACEHOLDER.DateFormat, dateFormatted);
};

export const getLangDate = (date) => {
    if (isDemoMode() || SITE_LANG === DEFAULT_LANG) {
        return (date) ? dayjs(date).format(DEMO_DATE_FORMAT) : date;
    }

    return (date) ? getDateText(date) : date;
};