// import "../css/style.css";
// import {priceHandler} from "./modules/price-handler";
// import {fetchPrices} from "./modules/fetch-prices";
// import {getPricesFromStorage, savePricesToStorage} from "./modules/local-storage";
// import {isDemoMode} from "./utils";
//
// const FETCH_PRICES_ERROR = 'Fetch prices error:';
// const storagePrices = getPricesFromStorage();
// const setTlPrices = (selector = '') => {
//     if (!storagePrices || isDemoMode()) {
//         fetchPrices()
//             .then((prices) => {
//                 savePricesToStorage(JSON.stringify(prices));
//                 priceHandler(prices, selector);
//             })
//             .catch((error) => {
//                 console.log(FETCH_PRICES_ERROR);
//             });
//     } else {
//         priceHandler(JSON.parse(storagePrices), selector);
//     }
// };
//
// window.setTlPrices = setTlPrices;
// setTlPrices();
// document.addEventListener("DOMContentLoaded", function() {
//     setTlPrices();
// },false);

import "../css/style.css";
import {priceHandler} from "./modules/price-handler";
import {fetchPrices} from "./modules/fetch-prices";
import {getPricesFromStorage, savePricesToStorage} from "./modules/local-storage";
import {isDemoMode} from "./utils";

const FETCH_PRICES_ERROR = 'Fetch prices error:';
const storagePrices = getPricesFromStorage();

const setTlPrices = (selector = '') => {
    if (!storagePrices || isDemoMode()) {
        fetchPrices()
            .then((prices) => {
                savePricesToStorage(JSON.stringify(prices));
                priceHandler(prices, selector);
            })
            .catch((error) => {
                console.log(FETCH_PRICES_ERROR);
                console.log(error);
            });
    } else {
        priceHandler(JSON.parse(storagePrices), selector);
    }
};

window.setTlPrices = setTlPrices;

document.addEventListener("DOMContentLoaded", function() {
    setTlPrices();
},false);

window['priceAutoload'] = function() {
    setTlPrices();
}
