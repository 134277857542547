import {DEFAULT_LANG, DEFAULT_OFFER_LANG_INDEX, PLACEHOLDER} from "../const";
import {isDemoMode} from "../utils";
import {LANG_SETTING} from "../settings";

export const getOfferText = (offer) => LANG_SETTING[SITE_LANG].OfferText.replace(PLACEHOLDER.Offer, offer);

export const getLangOffer = (offer) => {
    const availableLangs = Object.keys(offer);

    offer = offer[SITE_LANG] ? offer[SITE_LANG] : offer[availableLangs[DEFAULT_OFFER_LANG_INDEX]];

    if (isDemoMode() || SITE_LANG === DEFAULT_LANG) {
        return offer;
    }

    return (offer) ? getOfferText(offer) : offer;
};