import {
    ADULTS_ATTRIBUTE,
    CURRENCY_ATTRIBUTE,
    DATE_ATTRIBUTE, FILL_STATUS_ATTRIBUTE,
    HOTEL_ATTRIBUTE, LINK_ATTRIBUTE, LINK_BOOK_URL_ATTRIBUTE,
    MIN_LOS_ATTRIBUTE, OFFER_CODE_ATTRIBUTE, OFFER_NAME_ATTRIBUTE, RESTORE_STATUS_ATTRIBUTE,
    ROOM_TYPE_ATTRIBUTE
} from '../const';
import {isDemoMode} from "../utils";
import {getFormatPrice} from "./price-formating";
import {getLangDate} from "./lang-date";
import {getLangMinLos} from "./lang-minlos";
import {getLink} from "./link";
import {getLangOffer} from "./lang-offer";
import {getLangAdults} from "./lang-adults";
import {getSiteLanguage} from "./site-language";

export const priceHandler = (prices, selector) => {
    const moduleElements = selector !== undefined && selector !== ''
        ? document.querySelectorAll(`${selector}, ${selector} [${HOTEL_ATTRIBUTE}]`)
        : document.querySelectorAll(`[${HOTEL_ATTRIBUTE}]`);

    window.SITE_LANG = getSiteLanguage();

    moduleElements.forEach((field) => {
        const hotelId = field.getAttribute(HOTEL_ATTRIBUTE);
        const roomType = field.getAttribute(ROOM_TYPE_ATTRIBUTE);
        const adults = field.getAttribute(ADULTS_ATTRIBUTE);
        const currency = field.getAttribute(CURRENCY_ATTRIBUTE);

        const isDateField = field.hasAttribute(DATE_ATTRIBUTE);
        const isMinLosField = field.hasAttribute(MIN_LOS_ATTRIBUTE);
        const isOfferCodeField = field.hasAttribute(OFFER_CODE_ATTRIBUTE);
        const isOfferNameField = field.hasAttribute(OFFER_NAME_ATTRIBUTE);
        const isLinkField = field.hasAttribute(LINK_ATTRIBUTE);
        const hasRestoreStatusAttribute = field.hasAttribute(RESTORE_STATUS_ATTRIBUTE);
        const hasFillStatusAttribute = field.hasAttribute(FILL_STATUS_ATTRIBUTE);

        let priceInfo;

        if (!prices[hotelId]
            || (hotelId && !prices[hotelId]['hotelMinPrice'])
            || (roomType && !prices[hotelId]['rooms'][roomType])) return;

        if (roomType) {
            priceInfo = (adults) ? prices[hotelId]['rooms'][roomType]['guests'][adults] : prices[hotelId]['rooms'][roomType]['minPrice'];
        } else {
            priceInfo = prices[hotelId]['hotelMinPrice'];
        }

        if (!priceInfo) return;

        if (isDemoMode()) {
            if (hasRestoreStatusAttribute) {
                field.setAttribute(RESTORE_STATUS_ATTRIBUTE, priceInfo['isRestored'].toString());
                // return;
            }

            if (hasFillStatusAttribute) {
                field.setAttribute(FILL_STATUS_ATTRIBUTE, priceInfo['isFilled'].toString());
                // return;
            }

            if (isOfferCodeField) {
                field.innerHTML = priceInfo['ratePlan'] ? priceInfo['ratePlan']['code'] : '';
                return;
            }
        }

        if (isDateField) {
            field.setAttribute(DATE_ATTRIBUTE, priceInfo['date']);
            field.innerHTML = getLangDate(priceInfo['date']);
            return;
        }

        if (isMinLosField) {
            field.setAttribute(MIN_LOS_ATTRIBUTE, priceInfo['minLos']);
            field.innerHTML = getLangMinLos(priceInfo['minLos']);
            return;
        }

        if (isOfferNameField && priceInfo['ratePlan']) {
            field.setAttribute(OFFER_NAME_ATTRIBUTE, priceInfo['ratePlan']['code']);
            field.innerHTML = getLangOffer(priceInfo['ratePlan']['names']);
            return;
        }

        if (isLinkField) {
            const linkOptions = field.getAttribute(LINK_ATTRIBUTE);
            const bookUrl = field.getAttribute(LINK_BOOK_URL_ATTRIBUTE);

            field.href = getLink(linkOptions, bookUrl, hotelId, roomType, adults, priceInfo['date'], priceInfo['minLos'], priceInfo['ratePlan'] ? priceInfo['ratePlan']['code'] : '');
            return;
        }

        if (!currency && adults) {
            field.setAttribute(ADULTS_ATTRIBUTE, adults);
            field.innerHTML = getLangAdults(adults);
            return;
        }

        if (!currency) return;

        field.innerHTML = getFormatPrice(priceInfo['price'][currency]);
    });
};